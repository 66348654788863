import React, { ReactNode } from 'react';

export type ServiceGridItemProps = {
    icon: JSX.Element;
    title: string;
    image: ReactNode;
    description: string;
    button_name: string;
    link_to: string;
};

export const ServiceGridItem = ({ icon, title, image, description, button_name, link_to }: ServiceGridItemProps) => {
    return (
        <div className='flex flex-col w-full shadow-lg hover:shadow-xl hover:scale-105 transition-all rounded-xl cursor-pointer'>
            <div className='h-64'>{image}</div>
            <div className='flex flex-col items-center pt-3 pb-8 px-6 text-gray-700 h-80'>
                {icon}
                <div className='text-lg text-center font-bold uppercase mb-1'>{title}</div>
                <div className='text-sm text-justify'>{description}</div>
                <div className='mt-auto'>
                    <button className='w-fit text-sm font-semibold text-gray-50 bg-red-600 px-4 py-2 rounded-lg'>
                        {button_name}
                    </button>
                </div>
            </div>
        </div>
    );
};
