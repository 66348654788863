import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { BsSoundwave } from 'react-icons/bs';
import { GiBrain, GiCircuitry, GiCogLock } from 'react-icons/gi';
import { GoServer } from 'react-icons/go';
import { FaHardHat } from 'react-icons/fa';
import { MdDesignServices } from 'react-icons/md';
import { ImHammer2 } from 'react-icons/im';
import { ServiceGridItemProps } from './service-grid-item';

export const sevices_list: ServiceGridItemProps[] = [
    {
        icon: <GiCogLock className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'System Integration',
        image: (
            <StaticImage
                src='../../../../images/projects/battery-pack/photo2.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Expert System Integration services, tailored to your specific needs. Advanced technology solutions to improve efficiency, reduce costs, and achieve your business goals.',
        button_name: 'System Integration Projects',
        link_to: '',
    },
    {
        icon: <MdDesignServices className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'Design & 3D Printing',
        image: (
            <StaticImage
                src='../../../../images/projects/mlng/photo3.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Design & 3D Printing services for bringing your ideas to life. Our team creates detailed designs and prototypes, from concept to final product, utilizing the latest technology.',
        button_name: 'Design & 3D Printing Projects',
        link_to: '',
    },
    {
        icon: <GiBrain className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'Software & AI Development',
        image: (
            <StaticImage
                src='../../../../images/projects/coding.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Custom AI-powered solutions and software development. Our experts deliver innovative technology to drive your business forward.',
        button_name: 'Software & AI Projects',
        link_to: '',
    },
    {
        icon: <GiCircuitry className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'IOT Monitoring Solutions',
        image: (
            <StaticImage
                src='../../../../images/projects/bosch/photo2.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Real-time monitoring for plants and machines with our Smart Monitoring Solutions. Utilizing sensors to provide valuable insights and optimize performance. Tailored to your specific needs.',
        button_name: 'Smart Monitoring Projects',
        link_to: '',
    },
    {
        icon: <ImHammer2 className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'Jig & Panel Fabrication',
        image: (
            <StaticImage
                src='../../../../images/projects/conti-as/photo1.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Custom Jig & Panel Fabrication services for precise and efficient production. Our team of experts design, build and test jigs and panels for a variety of industries.',
        button_name: 'Jig & Panel Fabrication Projects',
        link_to: '',
    },
    {
        icon: <FaHardHat className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'On-site Engineering & Manpower',
        image: (
            <StaticImage
                src='../../../../images/projects/pdb/photo2.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'On-site Engineering & Manpower support for your projects. Skilled engineers and technicians available for installation, commissioning, maintenance, troubleshooting and repair.',
        button_name: 'On-site Engineering & Manpower Projects',
        link_to: '',
    },
    {
        icon: <BsSoundwave className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'Wireless Sensor Technology',
        image: (
            <StaticImage
                src='../../../../images/projects/iot-sensor/photo2.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Wireless Sensor Technology for remote monitoring. Real-time data collection and analysis for efficient maintenance and operation. Expert design, installation and maintenance of wireless sensor systems.',
        button_name: 'Wireless Sensor Technology Projects',
        link_to: '',
    },
    {
        icon: <GoServer className='fill-gray-600 mb-4' size='4.2rem' />,
        title: 'PLC & SCADA System Integration',
        image: (
            <StaticImage
                src='../../../../images/projects/iwk/photo6.jpg'
                alt=''
                className='h-full rounded-tl-lg rounded-tr-lg'
                objectPosition='top center'
            />
        ),
        description:
            'Expertly integrate PLC and SCADA systems for efficient automation and control. Our team has extensive experience in programming, commissioning, and troubleshooting to ensure smooth operation of your plant or facility.',
        button_name: 'PLC & SCADA System Integration Projects',
        link_to: '',
    },
];
