import React from 'react';
import { ServiceGridItem } from './service-grid-item';
import { sevices_list } from './services-data';

const ServicesGrid = () => {
    return (
        <section className='flex w-full justify-center pt-12 pb-40'>
            <div className='flex flex-col items-center w-11/12 max-w-7xl'>
                <div className='text-4xl text-gray-800 font-bold uppercase mb-4'>Our Full List of Services</div>
                <div className='bg-red-400 w-60 h-0.5 mb-14' />
                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-8'>
                    {sevices_list.map(service => (
                        <ServiceGridItem key={`service_grid_${service.title}`} {...service} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesGrid;
