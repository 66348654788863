import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ServicesStatement = () => {
    return (
        <section className='flex w-full justify-center'>
            <div className='flex flex-col w-11/12 max-w-7xl'>
                <div className='flex flex-col lg:flex-row items-center lg:py-20 py-10'>
                    <div className='flex flex-col w-full lg:w-2/3 lg:mr-12 mb-4 lg:mb-0'>
                        <div className='lg:text-2xl text-xl font-semibold text-gray-500 uppercase'>pacemy.co</div>
                        <div className='lg:w-10/12 lg:text-4xl text-3xl font-bold text-gray-800 uppercase'>
                            Expert Solutions for Your Business Needs
                        </div>
                        <div className='w-48 bg-red-400 h-0.5 my-4'></div>
                        <div className='lg:text-lg text-justify text-gray-700 mb-2'>
                            Our team of experts specialize in system integration, custom software development,
                            AI-powered solutions, industrial automation, and product design. We have the skills and
                            experience to deliver results that drive success across multiple industries including
                            factories, infrastructure IOT monitoring, and 5G towers. Let us help you achieve your
                            business goals.
                        </div>
                    </div>
                    <div className='relative flex justify-center lg:w-1/3 shadow-lg'>
                        <StaticImage
                            className='rounded-lg'
                            src='../../../images/projects/mll2/photo2.jpg'
                            objectFit='contain'
                            alt='pipeline iot monitoring'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesStatement;
