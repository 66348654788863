import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import PageHead from '../components/layout/page-head';
import TitleImage from '../components/layout/title-image';
import ServicesStatement from '../components/pages/services/services-statement';
import { ServicesGrid } from '../components/pages/services/services-grid';

const Services = () => {
    return (
        <Layout>
            <TitleImage title='Our Services'>
                <StaticImage
                    className='absolute w-full h-full z-0'
                    src='../images/services/text.png'
                    alt='people assembling robot project'
                />
            </TitleImage>
            <ServicesStatement />
            <ServicesGrid />
        </Layout>
    );
};

export const Head = () => (
    <PageHead
        title='Our Services'
        description="Transform your business with Pacific Centric Sdn Bhd's comprehensive services in system integration, custom software development, AI-powered solutions, industrial automation, and product design. Contact us to learn more."
    />
);

export default Services;
